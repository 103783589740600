import React from "react";
import PropTypes from "prop-types";
import { rhythm } from "utils/typography";
import Header from "./Header/Header";
import Footer from "./Footer";

function Layout({
  children,
  location,
  title,
  showHeader,
  showFooter,
  fullWidth = false,
  breadcrumbConfig
}) {
  return (
    <div
      style={{
        color: "var(--textNormal)",
        background: "var(--bg)",
        transition: "color 0.2s ease-out, background 0.2s ease-out",
        minHeight: "100vh",
        fontFamily: "var(--systemFont)"
      }}
    >
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: rhythm(100),
          padding: `1rem ${rhythm(3 / 4)}`
        }}
      >
        {showHeader && (
          <header
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: rhythm(35),
              marginBottom: "0.5rem"
            }}
          >
            <Header
              breadcrumbConfig={breadcrumbConfig}
              base="/"
              location={location}
              title={title}
            />
          </header>
        )}
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: fullWidth ? rhythm(100) : rhythm(35),
            padding: `0`
          }}
        >
          {children}
        </div>
        {showFooter && (
          <>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: rhythm(35),
                padding: `0`,
                marginTop: `5rem`
              }}
            >
              <Footer location={location} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object.isRequired,
  title: PropTypes.string,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  fullWidth: PropTypes.bool,
  breadcrumbConfig: PropTypes.object
};

Layout.defaultProps = {
  children: null,
  title: null,
  showHeader: true,
  showFooter: true,
  fullWidth: false,
  breadcrumbConfig: undefined
};

export default Layout;

