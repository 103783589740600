import React from "react";
import { Link } from "gatsby";
import "./footer.css";

function Footer() {
  const data = [
    "“If you only read the books that everyone else is reading, you can only think what everyone else is thinking.” - Murakami",
    '“Let yourself be silently drawn by the strange pull of what you really love. It will not lead you astray." - Rumi',
    '"The fool is the precursor to the savior." - Carl Jung',
    '"Be so good they can\'t ignore you." - Steve Martin',
    '"The only thing I know is that I know nothing" - Socrates',
    '"Never let the fear of striking out keep you from playing the game. – Babe Ruth"',
    '"There is only one way to avoid criticism: do nothing, say nothing, and be nothing. – Aristotle"',
    '"The future is purchased by the present."',
    '"The man on top of the mountain didn’t fall there. - Vince Lombardi"',
    '"Action expresses priorities - Gandhi"',
    '"The doer alone learneth. - Friedrich Nietzsche"',
    '"Those who lack the courage will always find a philosophy to justify it. - Albert Camus"',
    '"The empty vessel makes the loudest sound. - Plato"'
  ];
  const text = data[parseInt(Math.random() * data.length, 10)];
  return (
    <footer
      style={{
        marginTop: `2em`,
        padding: "1rem 0rem",
        color: `rgb(158 158 158)`,
        textAlign: `center`,
        borderTop: `1px solid rgba(209,209,209)`
      }}
    >
      <Link to="/quotes" className="footer-quote-link">
        <div
          style={{
            padding: `5rem 0rem`,
            fontStyle: `italic`,
            fontSize: `20px`,
            color: `rgb(158 158 158)`,
            textAlign: `center`
          }}
        >
          {text}
        </div>
      </Link>
    </footer>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
