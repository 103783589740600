import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import "./header.css";

function Header({ base, breadcrumbConfig }) {
  return (
    <div className="header-main">
      <div className="header-wrapper">
        {breadcrumbConfig && (
          <div className="header-breadcrumbs">
            <Link className="header-link" to={base}>
              <div className="home-icon" />
            </Link>
            <span className="slash">/</span>
            <Link to={breadcrumbConfig.url}>{breadcrumbConfig.content}</Link>
          </div>
        )}
        {!breadcrumbConfig && (
          <Link className="header-link" to={base}>
            Gagandeep Rangi
          </Link>
        )}
        <div className="header-links">
          <Link to="/about">About</Link>
          {/* <a href="https://instagram.com/gagandeeprangi1" target="blank" className="instagram-icon">
            <div />
          </a>
          <a href="https://twitter.com/gagandeeprangi1" target="blank" className="twitter-icon">
            <div />
          </a> */}
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  base: PropTypes.string,
  breadcrumbConfig: PropTypes.object
};

Header.defaultProps = {
  base: "",
  breadcrumbConfig: undefined
};

export default Header;

